
  @import '~@/assets/sass/dashboard/custom/variables';

.badge-vue {
  background-color: $vue;
}

.color-option {
  display: flex;
  align-items: center;
}

.color-radio {
  display: none;
}

.color-label {
  display: inline-block;
  width: 50px;  /* Increase the size of the circle */
  height: 50px;  /* Increase the size of the circle */
  border-radius: 50%;
  position: relative;
}

.color-label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;  /* Adjust the size of the inner circle */
  height: 20px;  /* Adjust the size of the inner circle */
  background: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.color-radio:checked + .color-label::before {
  opacity: 1;
}
