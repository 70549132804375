
.card-map {
  .map {
    height: 300px;
    width: 100%;
  }
  .custom-div-icon {
    text-align: center;
  }
}
