
@import '~@/assets/sass/dashboard/custom/variables';

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.big-icon {
  font-size: 25px;
}
.reserve-button {
  background: $vue;
}
